

// Poppins - new font
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
