@import './colors.scss';
@import './constants.scss';
@import './fonts.scss';

html {
  height: 100vh;
}

html, body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  min-height: 100vh;
}

h1 {
  font-weight: 400;
}
h2, h3 {
  font-weight: 500;
}
p, input, textarea, select, option {
  font-weight: 300;
}

input, textarea {
  caret-color: $blue-color;
}

svg {
  width: 100%;
  height: 100%;

  * {
    transition: fill .2s;
  }

  g {
    text {
      tspan {
        font-family: inherit;
      }
    }
  }
}
a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: $black-color;
  margin: 0;
}

@keyframes appearAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.page-holder {
  overflow-x: hidden;
  animation: appearAnimation .8s ease-in-out;

  position: relative;
  z-index: 1;

  min-height: calc(100vh - 619px);

  @media screen and (max-width: $small-screen) {
    min-height: calc(100vh - 604px);
  }
}

.default-cursor {
  cursor: default !important;
}

.padding-top {
  padding-top: 75px;

  @media screen and (max-width: $small-screen) {
    padding-top: 60px;
  }
}

// logo and nav buttons
.nevo-logo {
  width: 129px;
  height: 41px;

  @media screen and (max-width: $small-screen) {
    width: 108px;
    height: 34px;
  }
}
.nav-button {
  user-select: none;
  cursor: pointer;

  font-size: 18px;
  font-weight: 500;
  
  @media screen and (max-width: $small-screen) {
    font-size: 17px;
  }
}
.nevo-link {
  color: $blue-color !important;
}

.link {
  color: $blue-color !important;
  text-decoration: none;
  font-size: inherit;
}
.link:visited {
  color: $blue-color !important;
}
.link:hover {
  text-decoration: underline;
}

.padding {
  padding-left: 14vw;
  padding-right: 14vw;

  @media screen and (max-width: $small-screen) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.cooler-padding {
  padding-left: 11%;
  padding-right: 11%;

  @media screen and (max-width: $small-screen) {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.section {
  min-height: 500px;

  @media screen and (max-width: $small-screen) {
    min-height: 420px;
  }
  @media screen and (max-width: 820px) {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: unset;    
  }
  @media screen and (max-width: $mobile-screen) {
    padding-top: 50px;
    padding-bottom: 50px;  
  }
}
.image-section {
  margin-top: 0;
  margin-bottom: 0;

  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 820px) {
    height: 33vh;
    max-height: 300px;
  }
}

// page header 
.page-header-h1 {
  font-size: 60px;
  color: $white-color;
  margin-bottom: 8px;

  @media screen and (max-width: $small-screen) {
    font-size: 48px;
  }
  @media screen and (max-width: 690px) {
    font-size: 38px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 28px;
  }
}

.middle-header {
  font-size: 40px;
  @media screen and (max-width: $small-screen) {
    font-size: 32px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 26px;
  } 
}

.perfect-header {
  font-size: 36px;
  line-height: 50px;
  font-weight: 500;

  @media screen and (max-width: $small-screen) {
    font-size: 30px;
    line-height: 42px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 24px;
    line-height: 34px;
  }
}

.perfect-subtext {
  font-size: 24px;
  line-height: 40px;
  font-weight: 300;

  @media screen and (max-width: $small-screen) {
    font-size: 20px;
    line-height: 36px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 17px;
    line-height: 32px;
  }
}

.perfect-bio {
  font-size: 18px;
  line-height: 38px;
  font-weight: 300;

  @media screen and (max-width: $small-screen) {
    font-size: 16px;
    line-height: 32px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 14px;
    line-height: 28px;
  }
}

.text {
  font-size: 18px;
  line-height: 38px;

  @media screen and (max-width: $small-screen) {
    font-size: 16px;
    line-height: 32px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 14px;
    line-height: 28px;
  }
}

.cooler-line {
  line-height: 34px !important;

  @media screen and (max-width: $small-screen) {
    line-height: 30px !important;
  }
  @media screen and (max-width: $mobile-screen) {
    line-height: 26px !important;
  }
}

.button-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .spinner-holder {
    animation: appear .8s ease-out;
  }
}

.blue-button, .button-holder {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  
  cursor: pointer;

  border: 3px solid $border-blue-color;
  border-radius: 100px;
  background-color: transparent;
  
  color: $black-color;
  
  width: 100%;
  height: 40px;
  
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;

  cursor: pointer;
  transition: background-color .1s ease-in, border .1s ease-in, color .1s ease-in;
}
.blue-button:hover {
  color: $white-color;
  border: 3px solid $blue-hover-color;
  background-color: $blue-hover-color;
}
.blue-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.action-button {
  width: 400px;
  height: 83px;
  font-size: 24px;

  @media screen and (max-width: $small-screen) {
    width: 300px;
    height: 60px;
    font-size: 18px;
  }

  @media screen and (max-width: $mobile-screen) {
    width: 100%;
    height: 55px;
    max-width: 300px;
  }
}

.input-holder, .transparent-input {
  div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    background-color: $light-grey-color;
    border-radius: 0;
  
    input, textarea {
      width: 100%;
      outline: none;
      border: none;
      border-radius: 0;

      background-color: inherit;
      color: $black-color;

      margin-top: 2px;
      padding: 11px 25px;

      @media screen and (max-width: $mobile-screen) {
        padding: 11px 15px;
      }
    }

    textarea {
      min-height: 200px;
    }

    input::placeholder, textarea::placeholder {
      color: $grey-color;
    }

    .readonly-input {
      text-overflow: ellipsis;
      overflow: hidden; 
      white-space: nowrap;
    }
  }
}

.transparent-input {
  div {
    background-color: unset;
    border: 1px solid rgba($color: $grey-color, $alpha: 0.5);
    transition: border .2s ease-out;

    input, textarea {
      font-size: 16px;

      @media screen and (max-width: $mobile-screen) {
        font-size: 14px;
      }
    }
  }

  div:hover {
    border-color: $black-color;
  }
}

.fading-cover {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
}

.copyright-mark {
  font-size: 15px;
  color: $copyright-grey;
  font-weight: 300;

  @media screen and (max-width: $small-screen) {
    font-size: 14px;
  }
}

.invisible-element {
  opacity: 0;
  a {
    cursor: initial;
  }
}

.post-holder {
  width: 100%;
  max-width: 1315px;
  padding-top: 70px;
  margin-left: auto;
  margin-right: auto;

  .post-content {
    margin-left: auto;
    margin-right: auto;
    width: 86%;
    max-width: 1120px;
  }

  .post-type {
    font-size: 28px;
    color: $blue-color;
  }
  .post-title {
    margin-top: 7px;
    font-weight: 500;
    font-size: 70px;
    line-height: 106px;
  }
  .post-meta {
    margin-top: 10px;
    font-size: 24px;
    line-height: 39px;
    color: $copyright-grey;
  }
  .preview-image {
    margin-top: 43px;
    
    width: 100%;
    aspect-ratio: 1315 / 481;
    min-height: 125px;
    max-height: 480px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: $small-screen) {
    padding-top: 50px;
    max-width: 1050px;

    .post-type {
      font-size: 22px;
    }
    
    .post-title {
      margin-top: 4px;
      font-size: 58px;
      line-height: 88px;
    }

    .post-meta {
      margin-top: 6px;
      font-size: 18px;
      line-height: 29px;
    }

    .preview-image {
      margin-top: 30px;
      max-height: 384px;
    }
  }

  @media screen and (max-width: 1050px) {
    .post-content {
      width: 90%;
    }
  }

  @media screen and (max-width: $mobile-screen) {
    padding-top: 40px;

    width: unset;
    margin-left: 5vw;
    margin-right: 5vw;

    .post-content {
      width: unset;
    }

    .post-type {
      font-size: 18px;
    }

    .post-title {
      margin-top: 6px;
      font-size: 34px;
      line-height: 52px;
    }

    .post-meta {
      margin-top: 4px;
      font-size: 14px;
    }

    .preview-image {
      width: 100vw;
      position: relative;
      left: -5vw;
      max-height: 300px;
    }
  }
}

.load-more-button {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;
  height: 50px;
  border-radius: 25px;
  font-weight: 400;
  font-size: 18px;
}