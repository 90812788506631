
@import "../../styles/colors.scss";

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.scrollUp {
  position: fixed;
  z-index: 199;
  right: 0;
  bottom: 125px;

  width: 40px;
  height: 40px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  cursor: pointer;
  opacity: 0.75;
  background: $background-black-color;
  animation: slideInFromRight .4s ease-out;
}