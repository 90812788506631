$white-color: white;

$black-color: #1A1C1F;
$background-black-color: #1A1C1FB3; 
$background-grey-color: #1A1C1F1A;

$grey-color: #8D949D;
$grey-line-color: #afafaf;
$grey-border-color: #f5f4f5;
$grey-gradient-color: #DBDBDB;
$bubble-border-color: #707070;
$statistics-grey: #505254;
$faded-border-color: #70707054;
$ask-nevo-grey: #EBEBEC;
$light-grey-color: #F3F4F5;
$background-grey: #E5E8E8;
$cooler-grey: #F4F4F4;
$border-grey-color: #DEDEDE;
$cooler-dark-grey: #4F5154;
$grey-border: #C4C4C4;

$copyright-grey: #9EA0A0;

$blue-color: #0FE0D1;
$cooler-blue: #10DFD0;
$border-blue-color: #10e0d0;
$blue-hover-color: #0dbfb3;
$dark-blue: #0dbfb3;

$link-color: #0046FF;
$green-color: #17DE92;
$red-color: #d9534f;