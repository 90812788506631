@import "../../styles/colors.scss";
@import "../../styles/constants.scss";

.qaBanner {
  position: fixed;
  bottom: 0;
  left: 0;

  z-index: 999999;

  width: 100vw;
  height: 75px;
  
  background-color: rgba($color: $dark-blue, $alpha: 0.85);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  user-select: none;

  @media screen and (max-width: $small-screen) {
    height: 60px;
  }

  .qaHeader {
    font-size: 32px;
    color: $white-color;
  }
  
  .close {
    cursor: pointer;
    
    position: absolute;
    right: 15px;
    
    width: 15.5px;
  }
}